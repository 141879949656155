<template>
    <div class="flex flex-col gap-4">
        <span
            v-if="!justDisplay"
            class="text-primary-color-700 text-sm leading-5 font-semibold cursor-pointer"
            @click.stop="clearFilters"
            >Clear advanced filters</span
        >
        <div v-if="savedFiltersGroups">
            <template v-for="(filterGroup, index) in savedFiltersGroups" :key="index">
                <FilterGroup
                    :filters="filterGroup.filters"
                    :groupIndex="index"
                    :groupsLength="filtersGroups.length"
                    :justDisplay="justDisplay"
                    @addNewFilterToGroup="addNewFilterToGroup"
                    @removeGroup="removeGroup"
                    @removeFilter="removeFilter"
                    @editFilter="editFilter"
                />
                <div v-if="index + 1 < savedFiltersGroups.length" class="text-gray-500">OR</div>
            </template>
        </div>
        <div v-else>
            <template v-for="(filterGroup, index) in filtersGroups" :key="index">
                <FilterGroup
                    :filters="filterGroup.filters"
                    :groupIndex="index"
                    :groupsLength="filtersGroups.length"
                    :justDisplay="justDisplay"
                    @addNewFilterToGroup="addNewFilterToGroup"
                    @removeGroup="removeGroup"
                    @removeFilter="removeFilter"
                    @editFilter="editFilter"
                />
                <div v-if="index + 1 < filtersGroups.length" class="text-gray-500">OR</div>
            </template>
        </div>
        <div
            v-if="!justDisplay"
            class="w-fit px-2 py-0.5 bg-gray-200 text-gray-700 rounded cursor-pointer"
            @click.stop="addNewGroup"
        >
            OR
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import FilterGroup from "@/components/filters/FilterGroup.vue"
import { useContactsStore } from "@/stores/contacts"
import { useTaskStore } from "@/stores/task"
import { useAgentsStore } from "@/stores/agents"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useFormStore } from "@/stores/form"
import { useInboxStore } from "@/stores/inbox"
import { useTemplateStore } from "@/stores/template"
import { useJobsStore } from "@/stores/jobs"
import { useOriginStore } from "@/stores/origin"
import { useEventLogStore } from "@/stores/event-log"
import { useTagStore } from "@/stores/tag"
import { useWorkflowStore } from "@/stores/workflow"
import { useListingStore } from "@/stores/listings"
import { useInvoiceStore } from "@/stores/invoice"

import { Filter, FiltersGroup } from "@/shared/models/filter"

const contactStore = useContactsStore()
const taskStore = useTaskStore()
const agentsStore = useAgentsStore()
const formsStore = useFormStore()
const inboxStore = useInboxStore()
const templateStore = useTemplateStore()
const jobStore = useJobsStore()
const gridCollectionStore = useGridCollectionStore()
const originStore = useOriginStore()
const eventLogStore = useEventLogStore()
const tagStore = useTagStore()
const workflowStore = useWorkflowStore()
const listingStore = useListingStore()
const invoiceStore = useInvoiceStore()

interface Props {
    justDisplay?: boolean
    savedFiltersGroups?: FiltersGroup[]
    isWorkflow?: boolean
    branchIndex?: number
}

const props = withDefaults(defineProps<Props>(), {
    justDisplay: false,
    isWorkflow: false,
    branchIndex: 0,
})

const emit = defineEmits(["addNewFilter", "editFilter", "clearFilters", "removeGroup", "removeFilter"])

const filtersGroups = computed(() => gridCollectionStore.filtersGroups)

function addNewFilterToGroup(index: number) {
    if (props.isWorkflow) {
        workflowStore.branches[props.branchIndex].selectedFilterGroup = index
    } else {
        gridCollectionStore.selectedFilterGroup = index
    }
    emit("addNewFilter")
}

async function removeGroup(index: number) {
    if (props.isWorkflow) {
        workflowStore.branches[props.branchIndex].filtersGroups?.splice(index, 1)
        emit("removeGroup")

        return
    }
    gridCollectionStore.removeGroup(index)

    if (gridCollectionStore.collectionType === "contacts") {
        await contactStore.updateContactsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "tasks") {
        await taskStore.updateTasksGrid()
        return
    }
    if (gridCollectionStore.collectionType === "agents") {
        await agentsStore.updateAgentsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "forms") {
        await formsStore.updateFormsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "inbox") {
        await inboxStore.getInbox()
        return
    }
    if (gridCollectionStore.collectionType === "templates") {
        await templateStore.updateTemplatesGrid()
        return
    }
    if (gridCollectionStore.collectionType === "jobs") {
        await jobStore.updateJobsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "origins") {
        await originStore.updateOriginGrid()
        return
    }
    if (gridCollectionStore.collectionType === "workflows") {
        await workflowStore.updateWorkflowsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "logs") {
        await eventLogStore.updateEventLogsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "tag") {
        await tagStore.updateTagGrid()
        return
    }
    if (gridCollectionStore.collectionType === "listings") {
        await listingStore.updateListingGrid()
        return
    }
    if (gridCollectionStore.collectionType === "invoices") {
        await invoiceStore.updateInvoiceGrid()
        return
    }
}

function addNewGroup() {
    if (props.isWorkflow) {
        workflowStore.branches[props.branchIndex].selectedFilterGroup += 1
    } else {
        gridCollectionStore.selectedFilterGroup += 1
    }
    emit("addNewFilter")
}

function editFilter(filter: Filter, groupIndex: number, filterIndex: number) {
    if (props.isWorkflow) {
        workflowStore.branches[props.branchIndex].selectedFilterGroup = groupIndex
    } else {
        gridCollectionStore.selectedFilterGroup = groupIndex
    }
    emit("editFilter", filter, filterIndex)
}

async function removeFilter(groupIndex: number, filterIndex: number) {
    if (props.isWorkflow) {
        workflowStore.branches[props.branchIndex].filtersGroups[groupIndex].filters.splice(filterIndex, 1)
        emit("removeFilter")
        return
    }
    gridCollectionStore.removeFilter(groupIndex, filterIndex)

    if (gridCollectionStore.collectionType === "contacts") {
        await contactStore.updateContactsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "tasks") {
        await taskStore.updateTasksGrid()
        return
    }
    if (gridCollectionStore.collectionType === "agents") {
        await agentsStore.updateAgentsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "forms") {
        await formsStore.updateFormsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "inbox") {
        await inboxStore.getInbox()
        return
    }
    if (gridCollectionStore.collectionType === "templates") {
        await templateStore.updateTemplatesGrid()
        return
    }
    if (gridCollectionStore.collectionType === "jobs") {
        await jobStore.updateJobsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "origin") {
        await originStore.updateOriginGrid()
        return
    }
    if (gridCollectionStore.collectionType === "workflows") {
        await workflowStore.updateWorkflowsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "logs") {
        await eventLogStore.updateEventLogsGrid()
        return
    }
    if (gridCollectionStore.collectionType === "tag") {
        await tagStore.updateTagGrid()
        return
    }
    if (gridCollectionStore.collectionType === "listings") {
        await listingStore.updateListingGrid()
        return
    }
    if (gridCollectionStore.collectionType === "invoices") {
        await invoiceStore.updateInvoiceGrid()
        return
    }
}

function clearFilters() {
    emit("clearFilters")
}
</script>
